﻿/*********************************************************************/
/* Bootstrap 4 Multiple Item Carousel 000*/
/*https://www.jqueryscript.net/slider/responsive-bootstrap-carousel-multiple-items.html*/
/*css내용 수정됨. js내용은 완전히 바뀜. - ins*/
/*********************************************************************/

/*
    .multi-carousel 정사이즈로 표시하기  class="col-lg-4 col-md-6 col-12"
    .multi-carousel-s :작은사이즈로 표시하기 class="col-lg-2 col-md-3 col-6"    
    */
/* small - display 2  */
@media (min-width: 380px) {
    .multi-carousel-s .carousel-inner .carousel-item-right.active,
    .multi-carousel-s .carousel-inner .carousel-item-next {
        transform: translateX(50%);
    }

    .multi-carousel-s .carousel-inner .carousel-item-left.active,
    .multi-carousel-s .carousel-inner .carousel-item-prev {
        transform: translateX(-50%);
    }
}

/* medium - display 4  */
@media (min-width: 768px) {
    .multi-carousel-s .carousel-inner .carousel-item-right.active,
    .multi-carousel-s .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .multi-carousel-s .carousel-inner .carousel-item-left.active,
    .multi-carousel-s .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }

    .multi-carousel .carousel-inner .carousel-item-right.active,
    .multi-carousel .carousel-inner .carousel-item-next {
        transform: translateX(50%);
    }

    .multi-carousel .carousel-inner .carousel-item-left.active,
    .multi-carousel .carousel-inner .carousel-item-prev {
        transform: translateX(-50%);
    }
}

/* large - display 6 */
@media (min-width: 992px) {
    .multi-carousel-s .carousel-inner .carousel-item-right.active,
    .multi-carousel-s .carousel-inner .carousel-item-next {
        transform: translateX(16%);
    }

    .multi-carousel-s .carousel-inner .carousel-item-left.active,
    .multi-carousel-s .carousel-inner .carousel-item-prev {
        transform: translateX(-16%);
    }

    .multi-carousel .carousel-inner .carousel-item-right.active,
    .multi-carousel .carousel-inner .carousel-item-next {
        transform: translateX(33%);
    }

    .multi-carousel .carousel-inner .carousel-item-left.active,
    .multi-carousel .carousel-inner .carousel-item-prev {
        transform: translateX(-33%);
    }
}

/*@media (max-width: 768px) {
    .multi-carousel-s .carousel-inner .carousel-item > div {
        display: none;
    }

    .multi-carousel-s .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}*/

.multi-carousel .carousel-inner .carousel-item.active,
.multi-carousel .carousel-inner .carousel-item-next,
.multi-carousel .carousel-inner .carousel-item-prev,
.multi-carousel-s .carousel-inner .carousel-item.active,
.multi-carousel-s .carousel-inner .carousel-item-next,
.multi-carousel-s .carousel-inner .carousel-item-prev {
    display: flex;
}

.multi-carousel .carousel-inner .carousel-item-right,
.multi-carousel .carousel-inner .carousel-item-left,
.multi-carousel-s .carousel-inner .carousel-item-right,
.multi-carousel-s .carousel-inner .carousel-item-left {
    transform: translateX(0);
}

/*ins 추가*/
.multi-carousel .carousel-control-prev, .multi-carousel .carousel-control-next,
.multi-carousel-s .carousel-control-prev, .multi-carousel-s .carousel-control-next {
    height: 30%;
    top: 35%;
    opacity: 0.70;
    background-color: #c0c0c0;
    padding: 1em;
}
/***********************************************************************************************************/
