﻿@font-face {
    font-family: NanumSquare;
    src: url("./font/NanumSquareEB.woff") format('woff');
    font-weight: 900;
}

@font-face {
    font-family: NanumSquareR;
    src: url("./font/NanumSquareR.woff") format('woff');
    /*font-weight: 900;*/
}

img, embed, object, video {
    max-width: 100%;
}
body {    
    font-family: 'Malgun Gothic', sans-serif;
}

a.noactive:hover , a.noactive:active, a.noactive:link{
    color:initial;
}

/*리뷰평점관련 스타일*/
.star-ratings-css {
    width: 92px;
    height: 15px;
    position: relative;
}

.star-ratings-css .star-rating-on {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}