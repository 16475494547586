﻿$theme-colors: ( "primary": #ff5a5f,"secondary": #6c757d); // Bootstrap 테마 컬러 설정
$input-border-color: #e7e8e8 !default;

@import "~bootstrap/scss/bootstrap"; 

$theme-color: map-get($theme-colors,primary); // 부트스트랩 테마컬러값을 별도 테마컬러변수에 저장
$standard-color: #2e2e2e; //텍스트 기본컬러
$custom-gray: #e9e9e9; // 옅은 회색
$light-gray: #f8f9fa;
$title-color: #495057; //제목 텍스트 컬러
$content-color: #909090; //내용 작은 텍스트에 사용
$mid-gray: #c4c4c4; //중간톤 회색
$blue-color: #007bff; // 파란색
$orange-color: #ebb000; //오렌지색
//사이트버튼 스타일
//색상 순서 : bg,color,border
$site-themes: ( "1" : ( "bg":#2e2e2e, "color":#ffffff, "border":#2e2e2e ), "2" : ( "bg":#ebebeb, "color":#545454, "border":#cccccc ), "3" : ( "bg":#6b6b6b, "color":#ffffff, "border":#6b6b6b ), "4":( "bg":#f93126, "color":#ffffff, "border":#f93126 ) );
$site-themes-hover: ( "1" : ( "bg":#2e2e2e, "color":#ffffff, "border":#2e2e2e ), "2" : ( "bg":#ebebeb, "color":#545454, "border":#cccccc ), "3" : ( "bg":#6b6b6b, "color":#ffffff, "border":#6b6b6b ), "4":( "bg":#f93126, "color":#ffffff, "border":#f93126 ) );

@each $typenum,$site-theme in $site-themes {
    .site-theme-#{$typenum} {
        background-color: map-get(map-get($site-themes, $typenum), 'bg');
        color: map-get(map-get($site-themes, $typenum), 'color');
        border-color: map-get(map-get($site-themes, $typenum), 'border');
    }
}

@each $typenum,$site-theme in $site-themes-hover {
    .site-theme-#{$typenum}:hover {
        background-color: map-get(map-get($site-themes-hover, $typenum), 'bg');
        color: map-get(map-get($site-themes-hover, $typenum), 'color');
        border-color: map-get(map-get($site-themes-hover, $typenum), 'border');
    }
}

/*------------------bootstrap 스타일 임의 수정 부분------------------------*/
/* 혹시나 모를 모바일환경에서 콘텐츠 가로 삐져나옴을 방지하기 위해 설정*/
.container {
    overflow-x: hidden;
}

a:hover {
    color: $theme-color;
    text-decoration: none;
}

a {
    color: $standard-color;
}

/* 컬러관련 클래스 */
.text-standard {
    color: $standard-color;
}

.text-title {
    color: $title-color;
    opacity: 0.84;
}

.text-blue {
    color: $blue-color;
}

.text-orange {
    color: $orange-color;
}

.text-content {
    color: $content-color;
    opacity: 0.93;
}

.bg-custom {
    background-color: $custom-gray;
}

/* 커스텀 버튼 스타일 관련 */
.btn-theme {
    background-color: $theme-color;
    font-weight: 900;
    font-size: 1.1rem;
    color: white;
}

.btn-standard {
    background-color: $title-color;
    font-weight: 900;
    font-size: 1.1rem;
    color: white;
}

/* 텍스트 관련*/
.bold {
    font-weight: 700;
}

.bolder {
    font-weight: 900;
}

.c_bold { //약관내용등에 강조텍스트에 사용
    font-weight: 800;
    color: $title-color;
    opacity: 0.9;
}

.h1, .h2, .h3, .h4, .h5 { // 상단 제목에는 나눔스퀘어 폰트 적용
    font-family: NanumSquare;
}

/* 그림자 */
.shadow-custom {
    box-shadow: 0px 8px 20px rgba(60, 60, 60,0.2);
}

/* 창크기에 맞춰 반응형으로 크기가 변하는 제목텍스트 */
#responsive_title {
    font-size: 1.1rem;
    font-family: NanumSquare;
    font-weight: 900;
}

#responsive_title_s {
    font-size: 0.8rem;
    font-weight: 900;
}

#responsive_title_n {
    font-size: 0.8rem;
    font-weight: 900;
}

#responsive_title_b {
    font-size: 1.3rem;
    font-weight: 900;
}

.responsive_height {
    height: 80%;
}

.responsive_text {
    font-size: 0.8rem;
}

.responsive_text_size {
    font-size: 0.8em;
}

.responsive_date{
    font-size:1.1rem;
}

@include media-breakpoint-up(sm) {
    #responsive_title_s {
        font-size: 1rem;
        font-weight: 900;
    }
}
/*작은화면일때 스타일설정*/
@media (max-width: 359px) {
    .responsive_sm_hidden {
        display: none;
    }
}


@include media-breakpoint-up(md) {
    #responsive_title_s {
        font-size: 1.1rem;
        font-family: NanumSquare;
        font-weight: 900;
    }

    #responsive_title_b {
        font-size: 1.25rem;
        font-family: NanumSquare;
        font-weight: 900;
    }

    #responsive_title_n {
        font-size: 0.9rem;
        font-weight: 900;
    }

    .responsive_height {
        height: 100%;
    }

    .responsive_text {
        font-size: 1rem;
    }

    .responsive_text_size {
        font-size: 1em;
    }

    .responsive_date {
        font-size: 1.25rem;
    }
}

@include media-breakpoint-up(lg) {

    #responsive_title_b {
        font-size: 1.7rem;
        font-family: NanumSquare;
        font-weight: 900;
    }
}

@include media-breakpoint-up(xl) {
    #responsive_title {
        font-size: 1.25rem;
        font-family: NanumSquare;
        font-weight: 900;
    }
}
/* 외곽선 관련 */
.border-theme {
    border: 1px solid $theme-color;
}

.border-gray {
    border: 1px solid #e9e9e9;
}

.border-bottom { // 하단 외곽선 지정시 사용
    border-bottom: 1px solid $custom-gray;
}

.border-bottom-primary { // 하단 외곽선 지정시 사용
    border-bottom: 1px solid $theme-color;
}
/* jumbotron 스타일변경(회원가입 페이지등)*/
.jumbotron {
    background-color: #fff;
}
/* 커스텀 alert창 스타일*/
.alert-theme {
    border-top: 2px solid $theme-color;
    background-color: $custom-gray;
    color: $title-color;
}
/* 페이지이동 관련 스타일*/
.page-item.active .page-link {
    background-color: $theme-color;
    border-color: $theme-color;
    font-weight: 900;
}

.page-link {
    color: $content-color;
}

.page-link:hover {
    color: $standard-color;
}
/* form-control 관련 스타일*/
.form-control {
    color: black;
}

.form-control:focus {
    border-color: $theme-color;
    box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 0.2em rgba(255,90,95,0.25); /* input 셀레트시 테두리색 */
}
/* place holder 텍스트 색상변경(각 브라우져별 설정방식이 다름)*/
.form-control::placeholder { /* Chrom, firefox */
    color: $mid-gray;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $mid-gray;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: $mid-gray;
}

.form-control::-webkit-input-placeholder { /* safari */
    color: $mid-gray;
}
/* input-group 관련 스타일 */
.input-group { // 예약 요금 표시부분 스타일
    .input-left {
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 0.3rem 0 0 0.3rem;
    }

    .input-right {
        border-top: 1px solid rgba(0, 0, 0, .125);
        border-right: 1px solid rgba(0, 0, 0, .125);
        border-bottom: 1px solid rgba(0, 0, 0, .125);
        border-radius: 0 0.3rem 0.3rem 0;
    }
}

.left {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.3rem 0 0 0.3rem;
}

.center {
    background-color: #fff;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.right {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.3rem 0.3rem 0;
}
/* nav로 구성된 tab 레이아웃 스타일(고객센터, 회원정보 페이지등) */
.nav-tabs { //상단 밑줄
    border-bottom: 1px solid $theme-color;
}

.nav-tabs .nav-link.active { //활성화된 탭
    /*border-color: $theme-color $theme-color #fff;
        color: $theme-color;*/
    border-color: $theme-color;
    background-color: $theme-color;
    color: white;
    font-weight: 900;
    letter-spacing: -1px;
}

.nav-tabs .nav-link { //비활성화된 탭
    border-color: #88312c #88312c $theme-color;
    background-color: #88312c;
    color: #ac6f6c;
    font-weight: 900;
    letter-spacing: -1px;
}

#tap_content {
    .nav-link.active { //활성화된 탭
        background-color: $standard-color;
        font-weight: 900;
        letter-spacing: -1px;
    }
}

.nav-link { //OFF 탭 스타일
    border-color: #fff #fff $theme-color;
    /*color: $content-color;*/
    color: #fff;
    letter-spacing: -1px;
}
/* 테이블 스타일 추가*/
.thead-title {
    background-color: $custom-gray;
}

.table a {
    color: black;
}

.table a:hover {
    color: $theme-color;
}

/*----------------------------------------------------------------------------*/
/* 반응형 테이블 스타일(sm일때 제목을 세로배치) */
@include media-breakpoint-down(sm) {
    /* Force table to not be like tables anymore */
    #custom-responsive-table table,
    #custom-responsive-table thead,
    #custom-responsive-table tbody,
    #custom-responsive-table th,
    #custom-responsive-table td,
    #custom-responsive-table tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    #custom-responsive-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #custom-responsive-table tr {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1.5em;
    }

    #custom-responsive-table td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 5em; /*컬럼위치조정*/
        white-space: normal;
        text-align: left;
    }

    #custom-responsive-table td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0.7em;
        left: 0.5em;
        width: 5em;
        padding-right: 0.5em;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }
    /*
	Label the data
	*/
    #custom-responsive-table td:before {
        content: attr(data-title);
    }
}
/* 메인배너 관련 */
#main-banner {
    .carousel-inner {
        object-fit: cover;
    }

    .carousel-caption { //배너위 텍스트 스타일지정
        bottom: 20%;
    }

    .t-shadow-dark { //밝은 이미지일경우 글자 그림자
        text-shadow: 0px 2px 3px rgba(46,46,46,0.8);
    }

    .t-shadow-white { //어두운 계열 이미지 글자 그림자
        text-shadow: 0px 1px 10px rgba(255,255,255,1);
    }
}
/* 메인 메뉴 관련 */
#rentinjeju_mainmenu {
    #main-logo {
        width: 120px;
    }

    @include media-breakpoint-down(lg) {
        #main-logo {
            width: 90px;
        }
    }

    #menu_login, #menu_logout {
        font-size: 0.8em;
    }

    .nav-link {
        color: $standard-color;
    }

    .nav-link:hover {
        color: $theme-color;
    }

    #mobile-menu {

        .nav-link:hover {
            color: $theme-color;
        }
    }

    #side-menu {
        .nav-link:hover {
            font-weight: 900;
        }
    }

    #side-menu-sm { //모바일 환경에서 나오는 드롭다운 메뉴
        .dropdown-menu {
            border-top: 2px solid $theme-color;
            left: -120%;
            min-width: 8rem;
        }

        .dropdown-item:hover {
            background-color: $theme-color;
            color: #fff;
        }
    }
}
/* 검색창 대여일시 및 반납일시 선택 달력창 스타일*/
$cal-width: 100%;
$day-width: 14.25%;
$day-marginx: 0;
$cal-select-color: #ff5a5f;

.cal_style {
    width: $cal-width;
    margin: auto;
    //년도 월 위치 변경
    .flatpickr-current-month {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
    //년도뒤에 년 글자 넣기
    .flatpickr-current-month .numInputWrapper:after {
        content: "년";
        color: #000;
    }
    //margin은 뒤에 년도사이즈만큼
    .flatpickr-current-month .numInputWrapper {
        white-space: nowrap;
        margin-right: 20px;
        margin-left: -20px;
        text-align: right;
        flex: 1 1 auto !important;
    }
    //텍스트는 오른쪽 정렬
    .flatpickr-current-month .numInputWrapper input {
        text-align: right;
        padding: 0 0 0 .5ch;
    }
    //년도 숫자 up down 아이콘 위치 왼쪽정렬
    .flatpickr-current-month .numInputWrapper span {
        left: 0;
    }
    //월 화살표제거
    .flatpickr-current-month select.flatpickr-monthDropdown-months {
        flex: 1 1 auto !important;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .flatpickr-current-month select::-ms-expand {
        display: none;
    }
    //사이즈 설정
    //기존 요일 너비가 307.875px로 되어 있고, 날짜 하나에 14%퍼센트 넣은것을
    //너비 315px 날짜 하나에 45px(마진포함)로 변경
    .flatpickr-days, .flatpickr-calendar {
        width: $cal-width;
    }

    .flatpickr-innerContainer .flatpickr-rContainer {
        flex: 1 1 auto !important;
    }
    //요일표시 디자인
    .flatpickr-innerContainer .flatpickr-weekdays {
        border-top: solid 3px $cal-select-color;
    }

    .dayContainer {
        width: $cal-width;
        max-width: $cal-width;
        min-width: 250px;
        justify-content: center;
    }

    .flatpickr-day {
        width: $day-width;
        max-width: $day-width;
        margin-left: $day-marginx;
        margin-right: $day-marginx;
    }

    .flatpickr-day:hover {
        width: $day-width;
        max-width: $day-width;
        margin-left: $day-marginx;
        margin-right: $day-marginx;
    }
    //선택날짜 사이색상
    .flatpickr-days .dayContainer .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-days .dayContainer .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-days .dayContainer .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
        -webkit-box-shadow: -10px 0 0 $cal-select-color;
        box-shadow: -10px 0 0 $cal-select-color;
        width: $day-width;
    }
    //선택날짜 색상(시작일,끝일)
    .flatpickr-day.selected, .flatpickr-day.startRange,
    .flatpickr-day.endRange, .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
        background: $cal-select-color;
        color: #fff;
        border-color: $cal-select-color;
        width: $day-width;
    }

    .dayContainer .flatpickr-day.startRange {
        /*-webkit-box-shadow: 10px 0 0 $cal-select-color;
        box-shadow: 10px 0 0 $cal-select-color;*/
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .dayContainer .flatpickr-day.endRange {
        /*-webkit-box-shadow: -10px 0 0 $cal-select-color;
        box-shadow: -10px 0 0 $cal-select-color;*/
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /*선택한 날짜 사이 기간 색상 같은걸로 해야함.*/
    .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange,
    .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange,
    .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange,
    .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover,
    .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus,
    .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
        //-webkit-box-shadow: -10px 0 0 $cal-select-color, 10px 0 0 $cal-select-color;
        background: $cal-select-color;
        border-color: $cal-select-color;
    }

    .flatpickr-day.inRange {
        /*border-radius: 0;*/
        -webkit-box-shadow: -10px 0 0 $cal-select-color, 10px 0 0 $cal-select-color;
        box-shadow: -10px 0 0 $cal-select-color, 10px 0 0 $cal-select-color;
        background: $cal-select-color;
        border-color: $cal-select-color;
    }
}

.picker__box {
    padding: 1em 1.5em 1em 1.5em;

    .picker__header { //제목 부분
        font-family: "Nanum square", sans-serif;
        margin-left: 1em;
        margin-right: 1em;
        padding-bottom: 1em;

        .picker__month { // 월표시
            font-weight: 700;
        }

        .picker__nav--next, .picker__nav--prev { //상단 월이동 좌,우 버튼
            background-color: $custom-gray;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            padding: 0.6em 0.7em 0.6em 0.7em;
        }
    }

    .picker__table { // 달력 스타일
        border: 1px solid #e5e5e5;
        font-size: 1.2rem;

        thead { //요일 줄
            border-top: 3px solid $theme-color;
            border-bottom: 1px solid #e5e5e5;
            font-size: 1.2rem;
        }

        .picker__weekday { //요일 스타일
            padding-top: 0.8em;
            padding-bottom: 0.8em;
            color: $content-color;
        }

        .picker__day { //일 표시
            padding-top: 0.6em;
            padding-bottom: 0.6em;
        }
    }
}
/* 서브페이지별 상단 페이지 타이틀*/
#sub_title {
    background-color: $light-gray;
    border-bottom: 2px solid $theme-color;
}
/* 예약진행중 하단 요금 표시 부분*/
#rev_info_win {
    box-shadow: 0px 6px 30px #ccc;
}
/*  입력창 필수항목 에러 메시지 스타일*/
.field-validation-error {
    span:before {
        content: '※';
    }
}
/* 달력 부분 스타일 일부 변경*/
.flatpickr-day.selected, .flatpickr-day.nextMonthDay.selected {
    background-color: $theme-color;
    border: 1px solid $theme-color;
}

.flatpickr-day.selected:hover {
    background-color: black;
    border: 1px solid black;
}

.flatpickr-weekdays {
    background-color: $custom-gray;
    margin-top: 1em;
}
/* 메인 리스트 배너 슬라이더 관려*/
.gallery-cell {
    margin-right: 1.5em;
    counter-increment: gallery-cell;
    width: 350px;
    height: 220px;
}

.gallery-cell {
    a {
        color: $standard-color;
    }

    a:hover {
        color: $theme-color;
        text-decoration: none;
    }
}
//상단메뉴 모바일 고정 설정
#top_menu_wrap {
    margin-bottom: 80px;
    background-color: #fff;
    padding: 0px;
}

#top_menu_outer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 67px;
    padding: 0px;
    box-shadow: 0px 5px 10px rgba(60, 60, 60,0.2);
    background-color: #fff;
}

/* 2020.10.22 불필요한것 같아서 삭제. 강명수.
//고객만족도 테마
.br-widget{
    display:flex;
}

.br-widget a {
    flex: 1 1 auto !important;
}*/
//기본
/*.br-theme-bars-pill .br-widget a {
    text-align: center;
    background-color: #eeeeee; //#bef5e8;
    color: #989898; //#50E3C2;
    text-decoration: none;
    font-size: .8em;
    padding:0;
    text-align: center;
    font-weight: 400;
    display: inline-block;
    padding: 3px 0;
    width: 20%;
}

//선택시
.br-theme-bars-pill .br-widget a.br-active, .br-theme-bars-pill .br-widget a.br-selected {
    background-color: #ffe400;//#50E3C2;
    color: white;
}
*/

/*작은화면에서 끝까지 안나와서 추가함*/
.br-theme-bars-pill .br-widget a {
    padding: 5px 9px;
    font-size:.9em;
}

@include media-breakpoint-up(sm) {
    .br-theme-bars-pill .br-widget a {
        padding: 7px 15px;
        font-size: 1em;
    }
}

@include media-breakpoint-up(md) {
    #top_menu_wrap {
        margin-bottom: 0px;
        background-color: #fff;
    }

    #top_menu_outer {
        position: static;
        height: 70px;
        margin-bottom: 0px;
        box-shadow: 0px 0px 0px rgba(60, 60, 60,0.2);
    }

    .br-theme-bars-pill .br-widget a {
        font-size: 1em;
    }
}

@include media-breakpoint-up(xl) {
    #top_menu_wrap {
        margin-bottom: 0px;
        background-color: #fff;
    }

    #top_menu_outer {
        position: static;
        height: 90px;
        margin-bottom: 0px;
        box-shadow: 0px 0px 0px rgba(60, 60, 60,0.2);
    }
}



// 상단메뉴내 사이드메뉴 부분
#sidebar {
    min-width: 10px;
    position: fixed;
    height: 100%;
    overflow: hidden;
    z-index: 1050;
    top: 0;
}

#sidebar #side-menu {
    min-width: 240px;
    min-height: 100vh;
}

#sidebar {
    transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transform: translateX(0px);
    -ms-transform: translateX(0px);
    -webkit-transform: translateX(0px);
}

#sidebar::-webkit-scrollbar {
    width: 0px;
}

.collapsing {
    visibility: visible;
    height: 0;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.collapsing.width {
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    width: 0;
    height: 100%;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.card-columns {
    @include media-breakpoint-only(lg) {
        column-count: 4;
    }

    @include media-breakpoint-only(lg) {
        column-count: 3;
    }

    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
}
