﻿table.ins_table_01 {    
    margin-left: auto;
    margin-right: auto;
    margin-top:1em;
    margin-bottom:1em;
    border-collapse: collapse;    
    line-height: 1.5em;
    border-left: 1px solid #ccc;
}

table.ins_table_01 thead th {
    vertical-align: middle;
    white-space: nowrap;
    padding: 1em;
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 2px solid #c00;
    background: #dcdcd1;
}

table.ins_table_01 tbody th{
    vertical-align: middle;
    white-space: nowrap;
    padding: 1em;
    font-weight: bold;
    vertical-align: top;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: #ececec;
}

table.ins_table_01 td {
    padding: 1em;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
